/** @jsxRuntime classic /
 /* @jsx jsx */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import "swiper/css/swiper.css"
/** @jsx jsx */
// @ts-ignore
import { jsx } from "@emotion/core"

import vantaImage from "../images/vanta_image.webp"

import "./index.css"


const TextTitle = styled.h1`
  ${tw`text-black text-9/2xl text-center font-normal`}
  @media (max-width: 767px) {
    ${tw`text-8`}
  }
`

const TextStandard = styled.h3` 
  ${tw`text-sm text-center font-normal`}
  color: #72707A;
  @media (max-width: 767px) {
    ${tw`text-5`}
  }
`

const AnnouncementContainer = styled.div`
  margin-top: 56px;
  background: white;
    font-family: Avenir;
    border-radius: 8px;
    border: 1px solid #C6C6C6;
    display: flex;
    .image-container {
        flex: 0.5;
        img {
            height: 100%;
            border-radius: 7px 0 0 7px;
        }
        @media (max-width: 769px) {
            display: none;
        }
    }
    }
    .text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 45px 24px 45px;
        align-items: start;
        .title {
            font-size: 1.5em;
            color: #0F1722;
            font-weight: 600;
            margin-bottom: 8px;
        }
        .content {
            margin-top: 24px;
            font-size: 1em;
            text-align: start;
        }
    }
`

const Announcements = () => {
  return (
    <React.Fragment>
      <AnnouncementContainer>
        <div className="image-container">
          <img src={vantaImage} alt={"Vanta Logo"} />
        </div>
        <div className="text-container">
          <TextTitle className="title">AirMason engages Vanta</TextTitle>
          <TextStandard className="date">August 1st, 2024</TextStandard>
          <TextStandard className="content">AirMason has engaged Vanta for its security; including SOC2 compliance;
            scheduled to be completed for Q3 of 2024.</TextStandard>
        </div>
      </AnnouncementContainer>
    </React.Fragment>
  )
}

const index = () => {
  return (
    <div className="text-center mb-16 px-2">
      <TextTitle>
        Enterprise Security and Privacy at AirMason
      </TextTitle>
      <Announcements />
    </div>
  )
}

export default index
