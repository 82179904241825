import React from "react"
// import styled from "styled-components"

import Header from "src/componentsV2/sections/Header"

import { WrapperSecurity } from "./shared/Layout"
import { ContainerPage } from "../ui/layout"
import AnnouncementPanel from "./AnnouncementPanel"
import DataProtection from "./DataProtection"
import SecuritySection from "./SecuritySection"
import ProductSecuritySection from "./ProductSecuritySection"
import ContinuousMonitoring from "./ContinuousMonitoring"

const Security = () => {
  return (
    <div style={{ background: "#f6f8fb" }}>
      <Header />
      <WrapperSecurity>
        <AnnouncementPanel />
        <DataProtection />
      </WrapperSecurity>
      <ContainerPage style={{ paddingTop: "60px", background: "#3D4353" }}>
        <WrapperSecurity>
          <SecuritySection />
        </WrapperSecurity>
      </ContainerPage>
      <ContainerPage style={{ paddingTop: "60px", background: "#41898C" }}>
        <WrapperSecurity>
          <ContinuousMonitoring />
        </WrapperSecurity>
      </ContainerPage>
      <ContainerPage isBackgroundWhite>
        <WrapperSecurity>
          <ProductSecuritySection />
        </WrapperSecurity>
      </ContainerPage>
    </div>
  )
}

export default Security
