import styled from "styled-components"

export const StatusListStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    gap: 10px;
`

export const CardItemStyled = styled.div`
    width: 100%;
    max-width: 360px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px 20px;
    background-color: rgba(0,0,0,0.26);
    color: white;
    display: flex;
    gap: 30px; 


    .online-status {
        color: white;
        align-content: center;
        text-wrap: nowrap;
    }

    .card-title {
        font-family: Avenir;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
    }
`
export const CardQuesionsStyled = styled.div`
    max-width: 360px;
    width: 100%;
    padding: 43px 70px;
    text-align: center;
    border-radius: 12px;
    background-color: #107af1;
    box-shadow: 0 2px 70px 0 rgba(4, 2, 33, 0.13);

    .title-quesions {
        color: #ffffff;
        font-family: Calluna-Regular;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 37px;
        margin-bottom: 27px;
    }

    .button {
        width: 130px;
        border-radius: 20px;
        background-color: #ffffff;
        color: #107af1;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        padding: 10px 22px;
        margin: 0 auto;
        cursor: pointer;
    }
`
