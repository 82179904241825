import React from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { CardListStyled, CardItemStyled } from "./style"
import data_rest from "../images/data_rest.svg"
import data_transit from "../images/Data_transit.svg"
import data_comp from "../images/data_comp.svg"
import Text from "../../ui/Text"

interface CardProps {
  icon: string
  title: string
  description?: string
  features: String[]
}

const cards: Array<CardProps> = [
  {
    icon: data_rest,
    title: "Data at rest",
    features: [
      "All datastores with customer data, in addition to Google buckets, are encrypted at rest. Sensitive collections and tables also use row-level encryption with Google MySQL Cloud.",
      "This means the data is encrypted even before it hits the Google Cloud Database so that neither physical access, nor logical access to the database, is enough to read the most sensitive information.",
    ],
  },
  {
    icon: data_transit,
    title: "Data in transit",
    features: [
      "AirMason uses TLS 1.2 or higher everywhere data is transmitted over potentially insecure networks. Server TLS keys and certificates are managed by GCP and deployed via Application Load Balancers.",
    ],
  },
  {
    icon: data_comp,
    title: "Data compliance",
    features: [
      "Please view our in-depth Data Processing Addendum to learn more about how protect your data. Customers can also request a call directly with our engineering team through email at hello@airmason.com.",
    ],
  },
]

const CardItem = ({ data }: { data: CardProps }) => {
  return (
    <CardItemStyled style={{}}>
      <img className="card-image" src={data.icon} alt={data.title} />
      <div className="card-title">{data.title}</div>
      {data.description && (
        <div className="card-description">({data.description})</div>
      )}
      <div className="card-features">
        {data.features.map((feature, index) => (
          <div key={index} className="flex mt-5 items-start">
            <div className="features-item">{feature}</div>
          </div>
        ))}
      </div>
    </CardItemStyled>
  )
}

const DataProtection = () => {
  return (
    <React.Fragment>
      <Text.title
        css={css`
          ${tw`pb-14`}
          font-family: Lato !important;
        `}
      >
        Data Protection
      </Text.title>
      <CardListStyled className="desktop mb-10">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
      <CardListStyled className="mobile px-2 mb-10">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
    </React.Fragment>
  )
}

export default DataProtection
