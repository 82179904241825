import React from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { CardListStyled, CardItemStyled } from "./style"
import pen_test from "../images/Penetration.svg"
import vulnerability from "../images/Vulnerability.svg"
import Text from "../../ui/Text"

interface CardProps {
  icon: string;
  title: string;
  description?: string;
  features: String[];
}

const cards: Array<CardProps> = [
  {
    icon: pen_test,
    title: "Penetration Testing",
    features: [
      "AirMason engages with 3rd party penetration testing consulting firms in the industry at least annually.",
      "Our current preferred penetration testing partner is Packet Labs.",
      "All areas of the AirMason product and cloud infrastructure are in-scope for these assessments, and source code is fully available to the testers in order to maximize the effectiveness and coverage.",
      "We make summary penetration test reports available to all our enterprise clients upon request.",
    ],
  },
  {
    icon: vulnerability,
    title: "Vulnerability Scanning",
    features: [
      "AirMason requires vulnerability scanning at key stages of our Secure Development Lifecycle (SDLC).",
      "• We do both human as well as AI (Momentic) testing of code during pull requests and on an ongoing basis.",
      "• Malicious dependency scanning to prevent the introduction of malware into our software supply chain.",
      "• Container Analysis of running applications.",
      "• Network vulnerability scanning on a period basis.",
    ],
  },
]

const CardItem = ({ data }: { data: CardProps }) => {
  return (
    <CardItemStyled style={{}}>
      <img className="card-image" src={data.icon} alt={data.title} />
      <div className="card-title">{data.title}</div>
      {data.description && (
        <div className="card-description">({data.description})</div>
      )}
      <div className="card-features">
        {data.features.map((feature, index) => (
          <div key={index} className="flex mt-5 items-start">
            <div className="features-item">{feature}</div>
          </div>
        ))}
      </div>
    </CardItemStyled>
  )
}

const SecuritySection = () => {
  return (
    <React.Fragment>
      <Text.title
        css={css`
            ${tw`pb-14`}
            font-family: Lato !important;
        `}
      >
        Product Security
      </Text.title>
      <CardListStyled className="desktop">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
      <CardListStyled className="mobile">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
    </React.Fragment>
  )
}

export default SecuritySection
