import React from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { CardListStyled, CardItemStyled } from "./style"
import security_ed from "../images/security_ed.svg"
import identity_access from "../images/Identity_access.svg"
import Text from "../../ui/Text"

interface CardProps {
  icon: string;
  title: string;
  description?: string;
  features: String[];
}

const cards: Array<CardProps> = [
  {
    icon: security_ed,
    title: "Security Education",
    features: [
      "AirMason provides comprehensive security training to all employees upon onboarding and annually through educational modules within its security partner, Vanta.",
      "In addition, all new employees attend a mandatory live onboarding session centered around key security principles. All new engineers also attend a mandatory live onboarding session focused on secure coding principles and practices.",
      "AirMason's engineering team shares regular threat briefings, phishing attempt examples with employees to inform them of important security and safety-related updates that require special attention or action.",
    ],
  },
  {
    icon: identity_access,
    title: "Identity & Access Management",
    features: [
      "AirMason uses Google Workspaces to secure our identity and access management.",
      "We enforce the use of phishing-resistant authentication factors, using WebAuthn exclusively wherever possible.",
      "AirMason employees are granted access to applications based on their role, and automatically deprovisioned upon termination of their employment. Further access must be approved according to the policies set for each application.",
    ],
  },
]

const CardItem = ({ data }: { data: CardProps }) => {
  return (
    <CardItemStyled style={{}}>
      <img className="card-image" src={data.icon} alt={data.title} />
      <div className="card-title">{data.title}</div>
      {data.description && (
        <div className="card-description">({data.description})</div>
      )}
      <div className="card-features">
        {data.features.map((feature, index) => (
          <div key={index} className="flex mt-5 items-start">
            <div className="features-item">{feature}</div>
          </div>
        ))}
      </div>
    </CardItemStyled>
  )
}

const SecuritySection = () => {
  return (
    <React.Fragment>
      <Text.title
        css={css`
            ${tw`pb-14`}
            font-family: Lato !important;
            color: white !important;
        `}
      >
        Security
      </Text.title>
      <CardListStyled className="desktop">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
      <CardListStyled className="mobile">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </CardListStyled>
    </React.Fragment>
  )
}

export default SecuritySection
