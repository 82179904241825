import styled from "styled-components"

export const CardListStyled = styled.div`
    display: flex;
    width: 100%;
    margin: auto;
    gap: 20px;

    &.desktop {
        padding-bottom: 10px;

        .first-col {
            margin-right: 40px;
        }
    }

    &.mobile {
        display: none;
    }

    @media (max-width: 767px) {
        &.desktop {
            display: none;
        }

        &.mobile {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 10px;
        }
    }
`

export const CardItemStyled = styled.div`
    width: 100%;
    margin-bottom: 40px;
    border-radius: 8px;
    overflow: hidden;
    padding: 30px 28px 28px;
    background-color: #242731;
    color: white;

    @media (max-width: 767px) {
        margin-bottom: 20.5px;
    }

    .card-image {
        margin-bottom: 20px;
    }

    .card-title {
        font-family: Avenir;
        font-size: 21px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 37px;
    }

    .card-features .icon-check {
        margin-top: 3px;
        margin-right: 10px;
        width: 10px;
    }

    .card-features .features-item {
        font-size: 14px;
        font-family: Avenir;
        letter-spacing: 0;
        line-height: 18px;
    }
`
export const CardQuesionsStyled = styled.div`
    max-width: 360px;
    width: 100%;
    padding: 43px 70px;
    text-align: center;
    border-radius: 12px;
    background-color: #107af1;
    box-shadow: 0 2px 70px 0 rgba(4, 2, 33, 0.13);

    .title-quesions {
        color: #ffffff;
        font-family: Calluna-Regular;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 37px;
        margin-bottom: 27px;
    }

    .button {
        width: 130px;
        border-radius: 20px;
        background-color: #ffffff;
        color: #107af1;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        padding: 10px 22px;
        margin: 0 auto;
        cursor: pointer;
    }
`
