/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"
import { Footer } from "src/sections/landing"
import Security from "../componentsV2/Security"

const SecurityPage = ({ ...props }) => (
  <React.Fragment>
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Security</title>
      <meta
        name="description"
        content={`AirMason is committed to keeping your data safe and secure. Learn more about our security practices.`}
      />
    </Helmet>
    <Security {...props} />
    <Footer />
  </React.Fragment>
)

export default SecurityPage
