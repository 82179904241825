import React from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { StatusListStyled, CardItemStyled } from "./style"
import Text from "../../ui/Text"
import { BsCircleFill } from "react-icons/all"
import arrow from "../images/arrow.svg"
import { Link } from "gatsby"

const cards: string[] = [
  "AirMason Marketing Website",
  "AirMason API Server",
  "AirMason Admin Dashboard",
  "AirMason Handbook Editor",
  "AirMason Handbook Viewer",
]

const CardItem = ({ data }: { data: string }) => {
  return (
    <CardItemStyled style={{}}>
      <div className="online-status">
        <BsCircleFill size={10} color="white" />
        <span className="ml-2 font-light">Online</span>
      </div>

      <div className="card-title">{data}</div>
    </CardItemStyled>
  )
}

const SecuritySection = () => {
  return (
    <div className="max-w-md mx-auto">
      <Text.title
        css={css`
            ${tw`pb-8`}
            font-family: Lato !important;
            color: white !important;
        `}
      >
        Continuous Monitoring
      </Text.title>
      <Text.subtitle
        css={css`
            ${tw`pb-14`}
            color: white !important;
            text-align: center;
        `}
      >
        Please visit our
        {" "}
        <a
          href="https://status.airmason.com/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "white", fontWeight: "bold" }}
        >
          Status page
        </a>
        {" "}
        that actively monitors all our applications on the network
      </Text.subtitle>
      <StatusListStyled>
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
      </StatusListStyled>
      <Link
        to="https://status.airmason.com/"
        target="_blank"
        css={css`
            ${tw`mt-12 bg-black text-white flex mx-auto`}
            max-width: 290px;
            padding: 12px 25px;
            border-radius: 99px;
            text-decoration: none;
            align-items: center;
            font-family: Lato;
            font-weight: bold;
            justify-content: space-between;
        `}
      >
        Go to AirMason Status page
        <img
          src={arrow}
          alt="arrow"
          style={{ width: "20px", marginLeft: "10px", paddingTop: "3px" }}
        />
      </Link>
    </div>
  )
}

export default SecuritySection
